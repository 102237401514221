<template>
  <router-view :key="$route.fullPath" />
</template>

<script setup>

</script>

<style lang="css">
  
</style>
