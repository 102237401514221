import axios from "axios";

const baseUrl = "https://opticost.innovativetollsolution.com";
// const baseUrl = "http://localhost:8000";

async function apirequest(method, url, data = null) {


  try {
    const response = await axios({
      method,
      url: baseUrl + url,
      data: data ? data : null,
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export default apirequest;
