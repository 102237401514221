<template>
  <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl " id="navbarBlur"
    data-scroll="false">
    <div class="container-fluid py-1 px-3">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
          <li class="breadcrumb-item text-sm">
            <router-link class="opacity-5 text-white" :to="previousTitle">{{previousTitle.toUpperCase()}}</router-link>
          </li>
          <li class="breadcrumb-item text-sm text-white active" aria-current="page">{{pageTitle}}</li>
        </ol>
        <h6 class="font-weight-bolder text-white mb-0">{{pageTitle}}</h6>
      </nav>
      <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
        <div class="ms-md-auto pe-md-3 d-flex align-items-center">

        </div>
        <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
          <div class="ms-md-auto pe-md-3 d-flex align-items-center">
            <!-- <div class="input-group">
              <span class="input-group-text text-body"><i class="fas fa-search" aria-hidden="true"></i></span>
              <input type="text" class="form-control" placeholder="Type here..." :to="searchTitle">
            </div> -->
          </div>
        </div>
        <ul class="navbar-nav  justify-content-end">
          <li class="nav-item d-flex align-items-center">
            <a href="javascript:;" class="nav-link text-white font-weight-bold px-0">
              <i class="fa fa-user me-sm-1"></i>
              <span class="d-sm-inline d-none"> {{currentUser?.name}}</span>
            </a>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a href="javascript:void(0)" class="nav-link text-white p-0" id="iconNavbarSidenav">
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </li>
          <li class="nav-item px-3 d-flex align-items-center">
            <a href="javascript:;" class="nav-link text-white p-0" @click="logout">
              <i class="fa fa-sign-out fixed-plugin-button-nav cursor-pointer"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { useRoute } from 'vue-router'

export default {
  name: 'Topbar',
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
    pageTitle() {
      return useRoute().meta.title
    },
    searchTitle() {
      return useRoute().meta.title ?? "landing-page" 
    },
    previousTitle() {
       return useRoute().meta.back ?? "landing-page"
    },
  }, 
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout')
      document.location.href = "/login"
    },
    logout(){
    this.$router.push("/login");
    }
  }
}
</script>