import api from "./api";

class UtilsService {

  getStates() {
    return api.get("/states");
  }





  getClientDefaultUser(clientId) {
    return api.get(`/default-user/${clientId}`);
  }

  getuserdetails(userId) {
    return api.get(`/contact-user/${userId}`);
  }


 
  getDepartments(client) {
    return api.get(`/departments?client_id=${client}`);
  }
  getSubDepartments({ dept }) {
    return api.get("/sub-departments", null, { dept });
  }
  getSubsidiaries({ client }) {
    return api.get("/subsidiaries", null, { client });
  }
  getAuthUser() {
    return api.get(`/current_employee`);
  }
  async getOverviewDept(client) {
    return await api.get(`/default-department/${client}`);
  }
  getclientdetails(clientId) {
    return api.get(`/clients/${clientId}`);
  }
  getDept(deptId) {
    return api.get(`/departments/${deptId}`);
  }

 



 
}

export default new UtilsService();
