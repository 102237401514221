<template>
  <div class="min-height-300 bg-primary position-absolute w-100"></div>
  <Minisidebar />
  <main class="main-content position-relative border-radius-lg">
    <Topbar />
    <div class="container-fluid py-4">
      <div class="row mx-2 mt-4">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header pb-3">
              <div class="row">
                <div class="col-6 d-flex align-items-center">
                  <!-- body -->
                  <h6>Toll calculator</h6>

                  <!-- end body -->
                </div>
              </div>
            </div>

            <div class="card-body">
              <Form
                @submit="initMap"
                :validation-schema="fleetschema"
                class="form-horizontal"
                role="form"
                id="vehicles_upload_form"
              >
                <div class="form-group">
                  <div class="row col-12">
                    <div class="col-1">
                      <label class="control-label">Origin: </label
                      ><span class="text-danger">*</span>
                    </div>
                    <div class="col-4">
                      <div class="input-icon">
                        <vue-google-autocomplete
                          id="from"
                          ref="fromAddress"
                          classname="form-control"
                          placeholder="Start address"
                          v-on:placechanged="getfromData"
                          country="us"
                        >
                        </vue-google-autocomplete>
                      </div>
                    </div>
                    <div class="col-1">
                      <label class="control-label">Destination: </label
                      ><span class="text-danger">*</span>
                    </div>
                    <div class="col-4">
                      <div class="input-icon">
                        <vue-google-autocomplete
                          id="to"
                          ref="toAddress"
                          classname="form-control"
                          placeholder="Stop address"
                          v-on:placechanged="getToData"
                          country="us"
                        >
                        </vue-google-autocomplete>
                      </div>
                    </div>
                    <!-- <div class="col-1">
                      <button type="button" class="btn btn-secondary btn-outline btn-xs p-2 mr-1" data-dismiss="modal">
                        <i class="fas fa-times-circle"></i> More Filters
                      </button>
                    </div> -->
                    <div class="col-1">
                      <button
                        type="submit"
                        id="submit_btn"
                        class="btn btn-primary btn-outline btn-xs p-2 ml-1"
                      >
                        <i class="fa fa fa-pencil"></i> Submit
                      </button>
                    </div>
                  </div>
                </div>
              </Form>

              <div class="row col-12">
                <div id="googleMap" class="col-9"></div>
                <div class="col-3">
                  <div class="card mb-6 p-3 shadow text-center">
                    <h5 class="mb-3 fw-bold">Summary</h5>
                    <ul class="list-group text-start">
                      <li
                        class="list-group-item d-flex justify-content-between align-items-start"
                      >
                        <strong>Origin:</strong>
                        <span class="ms-auto">{{ origin }}</span>
                      </li>
                      <li
                        class="list-group-item d-flex justify-content-between align-items-start"
                      >
                        <strong>Destination:</strong>
                        <span class="ms-auto">{{ destination }}</span>
                      </li>
                      <li
                        class="list-group-item d-flex justify-content-between align-items-start"
                      >
                        <strong>Total Time:</strong>
                        <span class="ms-auto">{{ travel_time }}</span>
                      </li>
                      <li
                        class="list-group-item d-flex justify-content-between align-items-start"
                      >
                        <strong>Distance:</strong>
                        <span class="ms-auto">{{ distnce }}</span>
                      </li>
                      <li
                        class="list-group-item d-flex justify-content-between align-items-start"
                      >
                        <strong>Total Cost:</strong>
                        <span class="ms-auto">${{ toll_cost }}</span>
                      </li>
                    </ul>
                    <div class="row mt-3">
                      <div
                        class="col-md-5 d-flex justify-content-start align-items-center"
                        v-for="(agency, index) in agencyData"
                        :key="index"
                      >
                        <div
                          class="dot"
                          :style="{ backgroundColor: agency.color }"
                        ></div>
                        <div class="label ml-2">{{ agency.agency }}</div>
                      </div>
                    </div>
                    <div class="p-3 text-center">
                      <h5 class="mb-3 fw-bold">More Filters</h5>
                      <div class="mb-3">
                        <label for="filterOption1" class="form-label"
                          >Filter by Agency
                        </label>
                        <select
                          class="form-select"
                          id="filterOption1"
                          v-model="selectedAgency"
                        >
                          <option value="" selected>Select</option>
                          <option
                            v-for="(option, index) in agencyData"
                            :key="index"
                            :value="`${option.index}`"
                          >
                            {{ option.agency }}
                          </option>
                        </select>
                      </div>
                      <div class="mb-3">
                        <label for="filterOption2" class="form-label"
                          >Filter by Class</label
                        >
                        <select
                          class="form-select"
                          id="filterOption2"
                          v-model="selectedClass"
                        >
                          <option
                            v-for="index in 6"
                            :key="index"
                            :value="index"
                          >
                            {{ `Class ${index}` }}
                          </option>
                        </select>
                      </div>
                      <div class="mb-3">
                        <label for="filterOption2" class="form-label"
                          >Filter by Payment method</label
                        >
                        <select
                          class="form-select"
                          id="filterOption2"
                          v-model="selectedPaymentMethod"
                        >
                          <option
                            v-for="(option, index) in paymentMethodData"
                            :key="index"
                            :value="`${option.id}`"
                          >
                            {{ option.name }}
                          </option>
                        </select>
                      </div>
                      <div class="mb-3">
                        <label for="filterOption3" class="form-label"
                          >Time of Travel</label
                        >
                        <input
                          type="datetime-local"
                          class="form-control"
                          placeholder="MM/DD/YYYY"
                          v-model="travelTime"
                          id="filterOption3"
                        />
                      </div>

                      <div class="mb-3 row">
                        <label class="form-label col-12"
                          >Mode of Transport</label
                        >
                        <div class="col-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="transportMode"
                              checked
                              id="drivingOption "
                              v-model="travelMode"
                              value="DRIVING"
                            />
                            <label class="form-check-label" for="drivingOption">
                              Driving
                            </label>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="transportMode"
                              id="transitOption"
                              v-model="travelMode"
                              value="TRANSIT"
                            />
                            <label class="form-check-label" for="transitOption">
                              Transit
                            </label>
                          </div>
                        </div>
                      </div>
                      <br />
                      <button
                        type="submit"
                        class="btn btn-primary"
                        @click="processFilters"
                      >
                        Submit <i class="bi bi-check-circle"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <Footer /> -->
      <footer class="footer pt-3">
        <div class="container-fluid">
          <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-6 mb-lg-0 mb-4">
              <div
                class="copyright text-center text-sm text-muted text-lg-start"
              >
                Powered by
                <a
                  href="https://www.innovativetoll.com"
                  class="font-weight-bold"
                  target="_blank"
                  >Innovativetoll Solutions</a
                >
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </main>
</template>

<script>
import Minisidebar from "@/components/partials/Sidebar.vue";
import Topbar from "@/components/partials/Topbar.vue";
import Footer from "@/components/partials/Footer.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import "@/assets/css/dataTables.bootstrap4.min.css";
import "@/assets/css/buttons.dataTables.min.css";
const url = "http://127.0.0.1:8000/routeinterpolatecoordinates";
import { Form, Field, ErrorMessage } from "vee-validate";
import apirequest from "@/services/opticostApi.js";
export default {
  name: "signups",
  components: {
    Minisidebar,
    VueGoogleAutocomplete,
    Topbar,
    Footer,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      title: "",
      paymentMethodData: [],
      map: null,
      directionsService: null,
      directionsRenderer: null,
      directionsRenderer2: null,
      selectedPaymentMethod: 3,
      selectedAgency: "",
      selectedClass: 1,
      colorPalette: ["#3498db", "#e74c3c", "#2ecc71", "#f39c12"],
      axlesData: [],
      agencyData: [],
      fromlat: "",
      travelTime: this.getCurrentDateTimeString(),
      fromlng: "",
      from: "",
      tolat: "",
      tolng: "",
      to: "",
      distnce: "0",
      travel_time: "0",
      toll_cost: "0",
      origin: "-",
      destination: "-",
      travelMode: "DRIVING",
    };
  },
  methods: {
    getfromData: function (addressData, placeResultData, id) {
      this.fromlat = placeResultData.geometry.location.lat();
      this.fromlng = placeResultData.geometry.location.lng();
      this.from = placeResultData.formatted_address;
    },

    getToData: function (addressData, placeResultData, id) {
      this.tolat = placeResultData.geometry.location.lat();
      this.tolng = placeResultData.geometry.location.lng();
      this.to = placeResultData.formatted_address;
      console.log(this.to, this.tolat, this.tolng);
    },

    getCurrentDateTimeString() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const day = now.getDate().toString().padStart(2, "0");
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");

      // Format: YYYY-MM-DDTHH:MM
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    },

    initMap() {
      // Initialize the map
      let map = new google.maps.Map(document.getElementById("googleMap"), {
        center: { lat: 39.8283, lng: -98.5795 }, // Set your desired center coordinates
        zoom: 12,
      });

      // Initialize DirectionsService and DirectionsRenderer
      this.directionsService = new google.maps.DirectionsService();

      this.directionsRenderer = new google.maps.DirectionsRenderer({
        map: map,
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: "#7CFC00",
          strokeWeight: 4,
        },
      });

      // Create custom markers
      const f_lat = parseFloat(this.fromlat);
      const f_lng = parseFloat(this.fromlng);

      const t_lat = parseFloat(this.tolat);
      const t_lng = parseFloat(this.tolng);

      const startMarker = this.createMarker(
        map,
        { lat: f_lat, lng: f_lng },
        "Start Location",
        "green",
        {
          title: "Origin",
          body: this.from,
        }
      );
      const endMarker = this.createMarker(
        map,
        { lat: t_lat, lng: t_lng },
        "End Location",
        "red",
        {
          title: "Destination",
          body: this.to,
        }
      );

      const params = {
        origin: f_lat + "," + f_lng,
        destination: t_lat + "," + t_lng,
      };
      const body = {
        payment_method: this.selectedPaymentMethod,
        v_class: this.selectedClass,
        travel_date_time: this.travelTime,
      };
      const queryParams = new URLSearchParams(params);
      apirequest(
        "POST",
        `/routeinterpolatecoordinates?${queryParams}`,
        body
      ).then((response) => {
        var markers = [];

        const markes_response = response.markers;
        this.toll_cost = response.total_amount;
        this.agencyData = response.rates;

        markers.push(startMarker);
        for (let i = 0; i < markes_response.length; i++) {
          const marker = this.createMarker(
            map,
            markes_response[i].corrdinate,
            markes_response[i].name,
            "blue",
            {
              title: response.rates[i] ? response.rates[i].agency : "",
              body: `${markes_response[i].name} ${
                response.rates[i] ? "$" + response.rates[i].amount : ""
              }`,
            }
          );

          markers.push(marker);
        }
        markers.push(endMarker);

        this.calculateAndDisplayRoute(markers[0], markers[markers.length - 1]);

        for (let i = 0; i < response.rates.length; i++) {
          const rate = response.rates[i];
          if (rate.agency_type == 1) {
            const waypoints = [
              new google.maps.LatLng(rate["route_cordinate"]["entry"]), // Replace with your actual coordinates
              new google.maps.LatLng(rate["route_cordinate"]["exit"]), // Replace with your actual coordinates
              // Add more waypoints as needed
            ];

            this.drawRoute(map, waypoints, this.travelMode, rate.color, 8);
          }
        }
      });

      // Get directions and display on the map
    },

    createMarker(map, position, label, color, marker_info_objct) {
      var contentString =
        '<div class="info-window">' +
        `<h6>${marker_info_objct.title}</h6>` +
        `<p>${marker_info_objct.body}</p>` +
        "</div>";

      var infowindow = new google.maps.InfoWindow({
        content: contentString,
      });

      var marker = new google.maps.Marker({
        position: position,
        map: map,
        label: {
          text: label,
          color: color,
        },
        icon: {
          url: `http://maps.google.com/mapfiles/ms/icons/${color}-dot.png`,
          labelOrigin: new google.maps.Point(20, 0),
        },
      });
      marker.addListener("click", function () {
        infowindow.open(map, marker);
      });

      return marker;
    },

    calculateAndDisplayRoute(startMarker, endMarker, totalCost) {
      const start = startMarker.getPosition();
      const end = endMarker.getPosition();

      this.directionsService.route(
        {
          origin: start,
          destination: end,
          travelMode: this.travelMode,
        },
        (response, status) => {
          console.log(response);
          if (status === "OK") {
            // const output = document.querySelector('#output');
            // output.innerHTML = "<div class='alert-info'>From: " + response.routes[0].legs[0].start_address + ".<br />To: " + response.routes[0].legs[0].end_address + ".<br /> Driving distance <i class='fas fa-road'></i> : " + response.routes[0].legs[0].distance.text + ".<br />Duration <i class='fas fa-hourglass-start'></i> : " + response.routes[0].legs[0].duration.text + ".</div><br />Total Cost <i class='fas fa-cash'></i> : $" + totalCost + ".</div>";
            this.origin = response.routes[0].legs[0].start_address;
            this.destination = response.routes[0].legs[0].end_address;
            this.distnce = response.routes[0].legs[0].distance.text;
            this.travel_time = response.routes[0].legs[0].duration.text;

            this.directionsRenderer.setDirections(response);
          } else {
            window.alert("Directions request failed due to " + status);
          }
        }
      );
    },

    drawRoute(
      map,
      waypoints,
      travelMode = this.travelMode,

      strokeColor = "#0000FF",
      strokeWeight = 12
    ) {
      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer({
        map: map,
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: strokeColor,
          strokeWeight: strokeWeight,
        },
      });

      const request = {
        origin: waypoints[0],
        destination: waypoints[waypoints.length - 1],
        waypoints: waypoints
          .slice(1, -1)
          .map((waypoint) => ({ location: waypoint, stopover: true })),
        travelMode: travelMode,
      };

      directionsService.route(request, (response, status) => {
        if (status === "OK") {
          directionsRenderer.setDirections(response);
        } else {
          window.alert("Directions request failed due to " + status);
        }
      });
    },

    getAxels() {
      apirequest("get", "/axels").then((response) => {
        this.axlesData = response;
      });
    },
    // getAgencies() {
    //   apirequest("get", "/agency").then((response) => {
    //     this.agencyData = response;
    //   });
    // },
    getPaymentMethod() {
      apirequest("get", "/paymentmethods").then((response) => {
        this.paymentMethodData = response;
      });
    },

    processFilters() {
      this.getCurrentDateTimeString(),
      this.initMap();
    },
  },

  mounted() {
    this.map = new google.maps.Map(document.getElementById("googleMap"), {
      center: { lat: 39.8283, lng: -98.5795 }, // Set your desired center coordinates
      zoom: 6,
    });

    // this.getAgencies();
    this.getAxels();
    this.getPaymentMethod();
  },
};
</script>

<style>
#googleMap {
  height: 100vh;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;

  margin: 5px;
}
</style>
